<template>
  <div class="fx-main mg-2">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.97"
      :z-index="2147480000"
    ></loading>
    <div class="container">
      <div class="register-product-title">Cadastrar Produto</div>
      <!-- TODO: ADD STEP -->
      <div class="register-product-form">
        <div id="FormProduct">
          <div class="input-group fx-main mg-bot-2em input-text">
            <input
              class="default-border"
              type="text"
              placeholder="Nome do Produto"
              id="prod_form_name"
              v-model="product.name"
            />
          </div>

          <div class="input-group fx-main mg-bot-2em input-text">
            <money
              class="default-border"
              type="text"
              placeholder="Preço"
              id="prod_form_price"
              v-model="product.price"
              v-bind="money"
            ></money>
          </div>

          <div class="input-group fx-main mg-bot-2em input-text">
            <money
              class="default-border"
              type="text"
              placeholder="Preço (Plotted)"
              id="prod_form_plotted_price"
              v-model="product.plotted_price"
              v-bind="money"
            ></money>
          </div>

          <div class="input-group fx-main mg-bot-2em input-text">
            <input
              class="default-border"
              type="text"
              placeholder="Quantidade em Estoque"
              id="prod_form_stocked_quantity"
              v-model="product.stocked_quantity"
            />
            <span class="bd-input-text"></span>
          </div>

          <div class="input-group fx-main mg-bot-2em input-text">
            <input
              class="default-border"
              type="text"
              placeholder="Identificador Externo do Produto"
              id="prod_form_stocked_quantity"
              v-model="product.external_id"
            />
            <span class="bd-input-text"></span>
          </div>

          <div class="input-group fx-main mg-bot-2em input-text">
            <input
              class="default-border"
              type="text"
              placeholder="Curta Descrição do Produto"
              id="prod_form_stocked_quantity"
              v-model="product.short_description"
            />
            <span class="bd-input-text"></span>
          </div>

          <div class="input-group fx-main mg-bot-2em input-text">
            <textarea
              class="default-border"
              type="text"
              placeholder="Descrição"
              id="prod_form_description"
              v-model="product.description"
            />
          </div>

          <div class="input-group fx-main mg-bot-2em" v-if="product.categories">
            <label class="mg-right-2-em" for="prod_form_categories"
              >Categorias</label
            >
            <select
              multiple="multiple"
              class="border-radius-10 pd-0 scrollbar-none select-style"
              id="prod_form_categories"
              v-model="product.categories"
            >
              <option
                v-for="category in categories"
                :key="category.id"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </select>
          </div>

          <div class="input-group fx-main mg-bot-2em">
            <label class="mg-right-2-em" for="prod_form_image">Imagens</label>
            <span>
              Prefira imagens quadradas para evitar distorção. Formatos
              oficialmente aceitos: png, jpg, gif.
            </span>
            <input
              class="default-border"
              type="file"
              ref="fileInput"
              id="prod_form_image"
              multiple="multiple"
              @change="processFile($event)"
            />
          </div>
          <!-- 
          <div
            class="checkbox-type-product input-group fx-main mg-bot-2em fx-a-i-cent"
          >
            <span class="mg-right-2-em">Visível</span>
            <div class="switch">
              <input
                type="checkbox"
                class="switch__input"
                id="prod_form_published"
                v-model="product.published"
                :true-value="true"
                :false-value="false"
                checked
              />
              <label for="prod_form_published" class="switch__label"></label>
            </div>
          </div> -->

          <div
            class="checkbox-type-product input-group fx-main mg-bot-2em fx-a-i-cent"
          >
            <span class="mg-right-2-em">Visível</span>
            <div class="switch">
              <input
                type="checkbox"
                class="switch__input"
                id="prod_form_published"
                v-model="product.published"
                :true-value="true"
                :false-value="false"
                checked
              />
              <label for="prod_form_published" class="switch__label"></label>
            </div>
          </div>
           <hr>
          <div
            class="checkbox-type-product input-group fx-main mg-bot-2em fx-a-i-cent"
          >
            <span class="mg-right-2-em">Tangivel</span>
            <div class="switch">
              <input
                type="checkbox"
                class="switch__input"
                id="prod_form_tangible"
                v-model="product.tangible"
                :true-value="true"
                :false-value="false"
                checked
              />
              <label for="prod_form_tangible" class="switch__label"></label>
            </div>
          </div>
          <hr>
          <div class="input-group fx-main mg-bot-2em fx-a-i-cent">
            <span class="mg-right-2-em" for="prod_form_atacado">Atacado</span>
            <div class="switch">
              <input
                type="checkbox"
                class="switch__input"
                id="prod_form_atacado"
                v-model="product.price_rules_enabled"
                :true-value="true"
                :false-value="false"
              />
              <label for="prod_form_atacado" class="switch__label"></label>
            </div>
          </div>
          <hr>
          <div class="input-group fx-main mg-bot-2em fx-a-i-cent">
            <span class="mg-right-2-em" for="prod_form_varejo">Varejo</span>
            <div class="switch">
              <input
                type="checkbox"
                class="switch__input"
                id="prod_form_varejo"
                v-model="product.root_price_enabled"
                :true-value="true"
                :false-value="false"
              />
              <label for="prod_form_varejo" class="switch__label"></label>
            </div>
          </div>
          <hr>
          <div class="input-group fx-main mg-bot-2em fx-a-i-cent">
            <span class="mg-right-2-em" for="prod_form_published"
              >Comprável</span
            >
            <div class="switch">
              <input
                type="checkbox"
                class="switch__input"
                id="prod_form_sellable"
                v-model="product.sellable"
                :true-value="true"
                :false-value="false"
              />
              <label for="prod_form_sellable" class="switch__label"></label>
            </div>
          </div>
          <hr>
          <div
            class="checkbox-type-product input-group fx-main mg-bot-2em fx-a-i-cent"
          >
            <span class="mg-right-2-em" for="prod_form_recurrent"
              >Recorrente</span
            >
            <div class="switch">
              <input
                type="checkbox"
                class="switch__input"
                id="prod_form_recurrent"
                v-model="product.recurrent"
                :true-value="true"
                :false-value="false"
              />
              <label for="prod_form_recurrent" class="switch__label"></label>
            </div>
          </div>
          <hr>
          <br>
          <template v-if="product.tangible && product.box">
            <div class="input-group fx-main mg-bot-2em">
              <label class="mg-right-2-em" for="prod_form_weight"
                >Peso (g)</label
              >
              <input
                class="default-border"
                type="number"
                id="prod_form_weight"
                v-model="product.box.weight"
              />
            </div>
            <div class="input-group fx-main mg-bot-2em">
              <label class="product-input mg-right-2-em" for="prod_form_height"
                >Altura (cm)</label
              >
              <input
                class="default-border"
                type="number"
                id="prod_form_height"
                v-model="product.box.height"
              />
            </div>
            <div class="input-group fx-main mg-bot-2em">
              <label class="mg-right-2-em" for="prod_form_width"
                >Largura (cm)</label
              >
              <input
                class="default-border"
                type="number"
                id="prod_form_width"
                v-model="product.box.width"
              />
            </div>
            <div class="input-group fx-main mg-bot-2em">
              <label class="mg-right-2-em" for="prod_form_length"
                >Comprimento (cm)</label
              >
              <input
                class="default-border"
                type="number"
                id="prod_form_length"
                v-model="product.box.length"
              />
            </div>
          </template>

          <template v-if="product.recurrent && product.recurrence_info">
            <div class="input-group fx-main mg-bot-2em">
              <label class="mg-right-2-em" for="prod_form_weight"
                >Tipo de Intervalo</label
              >
              <select
                id="prod_form_weight"
                v-model="product.recurrence_info.payment_interval"
                class="default-border wd-100"
              >
                <option
                  v-for="interval_type in intervals"
                  :key="interval_type.value"
                  :value="interval_type.value"
                >
                  {{ interval_type.name }}
                </option>
              </select>
            </div>
            <div class="input-group fx-main mg-bot-2em">
              <label class="mg-right-2-em" for="prod_form_payment_number"
                >Intervalo Numérico</label
              >
              <input
                class="default-border"
                type="number"
                id="prod_form_payment_number"
                v-model="product.recurrence_info.payment_number"
              />
            </div>
          </template>
          <div class="input-group fx-main mg-bot-2em">
            <button class="btn clickable" v-on:click="registerProduct">
              Cadastrar Produto
            </button>
          </div>
        </div>
      </div>

      <!-- TODO: implementar modals -->
     <!-- <div class="" v-if="step === 2">
        Refinamento de cadastro
        <div class="input-group fx-main mg-bot-2em">
          <button
            id="btn_manage_images"
            class="default-border clickable btn style-button"
            @click="manageImages()"
          >
            GERENCIAR IMAGENS
          </button>
        </div>
        <div class="input-group fx-main mg-bot-2em">
          <button
            id="btn_manage_files"
            class="default-border clickable btn style-button"
            @click="manageFiles()"
          >
            GERENCIAR ARQUIVOS
          </button>
        </div>
        <div class="input-group fx-main mg-bot-2em">
          <button
            id="btn_manage_rules"
            class="default-border clickable btn style-button"
            @click="manageRules()"
          >
            GERENCIAR REGRAS DE PREÇO
          </button>
        </div>
        <div class="input-group fx-main mg-bot-2em">
          <button
            id="btn_manage_rules"
            class="default-border clickable btn style-button"
            @click="manageVariations()"
          >
            GERENCIAR VARIAÇÕES
          </button>

          <div class="input-group fx-main mg-bot-2em">
            <button
              class="btn clickable"
              v-on:click="updateProduct(productModal.id)"
            >
              Alterar Produto
            </button>
          </div>
        </div>
      </div> -->
    </div> 

    <!-- modal - gerenciar imagens -->
    <modal
      name="image-manage-modal"
      ref="imageManageModal"
      :style="variationIndex !== null ? 'z-index:9999' : ''"
      :adaptive="true"
      :width="'70%'"
      :height="'80%'"
      v-if="product"
    >
      <div class="pd-15-px modal-header fx-main fx-end">
        <i class="fa fa-times" aria-hidden="true" @click="hideImageModal"></i>
      </div>
      <div class="pd-15-px fx-main flex-md-fill">
        <div class="full-width flex-md-fill">
          <div id="FormImages">
            <div class="input-group fx-main mg-bot-2em fx-start">
              <div
                class="default-border fx-center col-md-2 mg-right-2em manage-img fx-wrap mg-top-2"
                v-for="(imageUrl, index) in variationIndex !== null
                  ? product.variations[variationIndex].imageUrl
                  : product.imageUrl"
                :key="imageUrl"
              >
                <img :src="imageUrl" :alt="'image-' + String(index)" />
                <div
                  class="label label-table label-danger clickable mg-top-2 left-img"
                  @click="leftImage(index)"
                >
                  <i class="fas fa-angle-left"></i>
                </div>
                <div
                  class="label label-table label-danger clickable mg-top-2 right-img"
                  @click="rightImage(index)"
                >
                  <i class="fas fa-angle-right"></i>
                </div>
                <div
                  class="label label-table label-danger clickable mg-top-2 del-img circle shadow"
                  @click="
                    eraseImage(
                      variationIndex !== null
                        ? product.variations[variationIndex].images[index]
                        : product.images[index]
                    )
                  "
                >
                  <i class="fas fa-trash clickable"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <!-- modal - gerenciar arquivos -->
    <modal
      name="file-manage-modal"
      ref="fileManageModal"
      :style="variationIndex !== null ? 'z-index:9999' : ''"
      :adaptive="true"
      :width="'70%'"
      :height="'80%'"
      v-if="productModal"
    >
      <div class="modal-header fx-main fx-end pd-15-px">
        <i class="fa fa-times" aria-hidden="true" @click="hideFileModal"></i>
      </div>
      <div class="pd-15-px fx-main flex-md-fill">
        <div class="full-width flex-md-fill">
          <div id="FormFiles">
            <div class="input-group fx-main mg-bot-2em fx-start">
              <div>
                <span class="mg-right-2-em">Nome:</span>
                <input
                  type="text"
                  class="default-border wd-100"
                  v-model="relatedFileCad.name"
                />
                <span class="mg-right-2-em">Descrição:</span>
                <input
                  type="text"
                  class="default-border wd-100"
                  v-model="relatedFileCad.description"
                />
                <span class="mg-right-2-em">Visível?</span>
                <input
                  type="checkbox"
                  v-model="relatedFileCad.published"
                  :true-value="true"
                  :false-value="false"
                />
                <span class="mg-right-2-em">Baixável?</span>
                <input
                  type="checkbox"
                  v-model="relatedFileCad.downloadable"
                  :true-value="true"
                  :false-value="false"
                />
                <span class="mg-right-2-em">Disponível Após Compra</span>
                <input
                  type="checkbox"
                  v-model="relatedFileCad.buyable"
                  :true-value="true"
                  :false-value="false"
                />
                <span class="mg-right-2-em">Selecionar Arquivo</span>
                <input
                  class="default-border"
                  type="file"
                  @change="
                    variationIndex == null
                      ? processRelatedFile($event)
                      : processVariationRelatedFile($event, variationIndex)
                  "
                  :ref="
                    'relatedFileInput_o_' +
                    (variationIndex == null ? 'RUT' : variationIndex)
                  "
                />
                <div
                  class="clickable mg-top-2 btn"
                  @click="addFiles(productModal.id, variationIndex)"
                >
                  Adicionar Arquivo
                  <i class="fas fa-plus"></i>
                </div>
              </div>
              <div
                class="default-border fx-center col-md-2 mg-right-2em manage-img fx-wrap mg-top-2"
                v-for="(relatedFile, index) in variationIndex !== null
                  ? productModal.variations[variationIndex].related_files
                  : productModal.related_files"
                :key="relatedFile.id"
              >
                <p>{{ relatedFile.name }}</p>
                <p
                  v-if="
                    relatedFile.description && relatedFile.description.length
                  "
                >
                  {{ relatedFile.description }}
                </p>
                <p v-if="relatedFile.is_loading">
                  <strong>Baixando...</strong>
                </p>
                <p v-if="relatedFile.blob_url">
                  <strong>Baixado! Clique novamente para salvar.</strong>
                </p>
                <a
                  :download="relatedFile.blob_url ? relatedFile.name : false"
                  @click="
                    popBlobIfNot(
                      productModal.id,
                      variationIndex
                        ? productModal.variations[variationIndex].id
                        : null,
                      relatedFile
                    )
                  "
                  :href="relatedFile.blob_url || 'javascript:void(0)'"
                >
                  <template v-if="relatedFile.blob_url">Salvar</template>
                  <template v-else>Baixar</template> Arquivo
                </a>
                <div
                  class="label label-table label-danger clickable mg-top-2 left-img"
                  @click="leftFile(index)"
                >
                  <i class="fas fa-angle-left"></i>
                </div>
                <div
                  class="label label-table label-danger clickable mg-top-2 right-img"
                  @click="rightFile(index)"
                >
                  <i class="fas fa-angle-right"></i>
                </div>
                <div
                  class="label label-table label-danger clickable mg-top-2 del-img"
                  @click="
                    eraseFile(
                      variationIndex !== null
                        ? productModal.variations[variationIndex].related_files[
                            index
                          ]
                        : productModal.related_files[index]
                    )
                  "
                >
                  <i class="fas fa-trash clickable"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <!-- modal - gerenciar regras de preço -->
    <modal
      name="rules-manage-modal"
      :adaptive="true"
      :width="'70%'"
      :height="'80%'"
      v-if="productModal"
    >
      <div class="pd-15-px modal-header fx-main fx-end">
        <i class="fa fa-times" aria-hidden="true" @click="hideRulesModal"></i>
      </div>
      <div class="pd-15-px fx-main flex-md-fill">
        <div class="full-width flex-md-fill">
          <div id="FormRules fx-a-i-start">
            <div class="clickable mg-top-2 btn" @click="addRule()">
              Adicionar Regra
              <i class="fas fa-plus"></i>
            </div>
            <div class="input-group fx-main mg-bot-2em mg-top-2 fx-start">
              <div
                class="default-border fx-main col-md-2 mg-right-2em fx-wrap"
                v-for="(rule, index) in productModal.price_rules"
                :key="index"
              >
                <span class="mg-right-2-em">Quantidade Mínima:</span>
                <input
                  type="number"
                  class="default-border wd-100"
                  v-model="rule.min_quantity"
                />
                <span class="mg-right-2-em">Quantidade Máxima:</span>
                <input
                  type="number"
                  class="default-border wd-100"
                  v-model="rule.max_quantity"
                />
                <span class="mg-right-2-em">Valor:</span>
                <money
                  v-if="rule.modifier_type === 'PRICE'"
                  class="default-border wd-100"
                  type="text"
                  v-model="rule.amount"
                  v-bind="money"
                ></money>
                <the-mask
                  v-if="rule.modifier_type === 'PERCENTAGE'"
                  v-model="rule.amount"
                  mask="##%"
                  type="text"
                  :masked="false"
                  class="default-border wd-100"
                ></the-mask>
                <span class="mg-right-2-em wd-100">Tipo de Valor:</span>
                <div class="fx-main wd-100">
                  <label>Porcentagem</label>
                  <input
                    type="radio"
                    :name="'rule_mod_' + index"
                    v-model="rule.modifier_type"
                    value="PERCENTAGE"
                  />
                </div>
                <div class="fx-main wd-100">
                  <label>Preço</label>
                  <input
                    type="radio"
                    :name="'rule_mod_' + index"
                    v-model="rule.modifier_type"
                    value="PRICE"
                  />
                </div>
                <div class="fx-main wd-100">
                  <label>Habilitada</label>
                  <input
                    type="checkbox"
                    v-model="rule.enabled"
                    :true-value="true"
                    :false-value="false"
                  />
                </div>
                <div class="fx-main wd-100">
                  <label>Relativa</label>
                  <input
                    type="checkbox"
                    v-model="rule.relative"
                    :true-value="true"
                    :false-value="false"
                  />
                </div>
                <div
                  class="label label-table label-danger clickable mg-top-2 del-img wd-100"
                  @click="eraseRule(index)"
                >
                  <i class="fas fa-trash clickable wd-100"></i>
                </div>
              </div>
              <div class="btn clickable mg-top-2" @click="saveRules()">
                Salvar
                <i class="fas fa-angle-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <!-- modal - variações -->
    <modal
      name="variations-manage-modal"
      :adaptive="true"
      :width="'70%'"
      :height="'80%'"
      v-if="productModal"
    >
      <div class="pd-15-px modal-header fx-main fx-end">
        <i
          class="fa fa-times"
          aria-hidden="true"
          @click="hideVariationsModal"
        ></i>
      </div>
      <div class="pd-15-px fx-main flex-md-fill">
        <div class="full-width flex-md-fill">
          <div id="FormRules fx-a-i-start">
            <div class="clickable mg-top-2 btn" @click="addVariation()">
              Adicionar Variação
              <i class="fas fa-plus"></i>
            </div>
            <div class="input-group fx-main mg-bot-2em mg-top-2 fx-start">
              <div
                class="default-border-MProduct fx-main col-md-2 mg-right-2em fx-wrap"
                v-for="(variation, index) in productModal.variations"
                :key="index"
              >
                <div class="input-group fx-main mg-bot-2em">
                  <label
                    class="mg-right-2-em"
                    :for="'prod-var-' + index + '_external_id'"
                    >Identificador Externo da Variação</label
                  >
                  <input
                    class="default-border"
                    type="text"
                    :name="'prod-var-' + index + '_external_id'"
                    :id="'prod-var-' + index + '_external_id'"
                    v-model="variation.external_id"
                  />
                </div>
                <div class="input-group fx-main mg-bot-2em">
                  <label
                    class="mg-right-2-em"
                    :for="'prod-var-' + index + '_name'"
                    >Nome da Variação</label
                  >
                  <input
                    class="default-border"
                    type="text"
                    :id="'prod-var-' + index + '_name'"
                    v-model="variation.name"
                  />
                </div>
                <div class="input-group fx-main mg-bot-2em">
                  <label
                    class="mg-right-2-em"
                    :for="'prod-var-' + index + '_form_price'"
                    >Preço</label
                  >
                  <money
                    class="default-border wd-100"
                    :id="'prod-var-' + index + '_form_price'"
                    type="text"
                    v-model="variation.price"
                    v-bind="money"
                    :enabled="!variation.extends_price"
                  ></money>
                </div>
                <div
                  class="input-group fx-main mg-bot-2em fx-start fx-a-i-cent"
                >
                  <span
                    class="mg-right-2-em"
                    :for="'prod-var-' + index + '_form_exp'"
                    >Herdar Preço</span
                  >
                  <input
                    type="checkbox"
                    :id="'prod-var-' + index + '_form_exp'"
                    v-model="variation.extends_price"
                    :true-value="true"
                    :false-value="false"
                  />
                </div>
                <div class="input-group fx-main mg-bot-2em">
                  <label
                    class="mg-right-2-em"
                    :for="'prod-var-' + index + '_stocked_quantity'"
                    >Estoque</label
                  >
                  <input
                    class="default-border"
                    type="number"
                    :id="'prod-var-' + index + '_stocked_quantity'"
                    v-model="variation.stocked_quantity"
                  />
                </div>
                <div class="input-group fx-main mg-bot-2em">
                  <label
                    class="mg-right-2-em"
                    :for="'prod-var-' + index + '_description'"
                    >Descrição</label
                  >
                  <textarea
                    class="default-border"
                    type="text"
                    :id="'prod-var-' + index + '_description'"
                    v-model="variation.description"
                  />
                </div>
                <!-- <div class="input-group fx-main mg-bot-2em" v-if="variation.categories">
                  <label class="mg-right-2-em" for="prod_form_categories">Categorias</label>
                  <select
                    multiple="multiple"
                    class="default-border"
                    id="prod_form_categories"
                    v-model="variation.categories"
                  >
                    <option
                      v-for="category in categories"
                      :key="category.id"
                      :value="category.id"
                    >{{ category.name }}</option>
                  </select>
                </div>-->
                <div class="input-group fx-main mg-bot-2em" v-if="variation.id">
                  <label class="mg-right-2-em" for="name"
                    >Adicionar Imagens</label
                  >
                  <input
                    class="default-border"
                    type="file"
                    multiple="multiple"
                    @change="processVariationFile($event, index)"
                    :ref="'fileInput_var_' + index"
                  />
                </div>
                <div class="input-group fx-main mg-bot-2em" v-if="variation.id">
                  <button
                    id="btn_manage_images_variation"
                    class="default-border"
                    @click="manageImages(index)"
                  >
                    Gerenciar Imagens
                  </button>
                </div>
                <div class="input-group fx-main mg-bot-2em" v-if="variation.id">
                  <button
                    id="btn_manage_files_variation"
                    class="default-border"
                    @click="manageFiles(index)"
                  >
                    Gerenciar Arquivos
                  </button>
                </div>
                <div
                  class="input-group fx-main mg-bot-2em fx-start fx-a-i-cent"
                >
                  <span
                    class="mg-right-2-em"
                    :for="'prod-var-' + index + '_form_exp'"
                    >Herdar Imagens</span
                  >
                  <input
                    type="checkbox"
                    :id="'prod-var-' + index + '_form_exp'"
                    v-model="variation.extends_images"
                    :true-value="true"
                    :false-value="false"
                  />
                </div>
                <div
                  class="icon-circle label label-table label-danger clickable mg-top-2 del-img wd-100 shadow fx-center circle"
                  @click="eraseVariation(index)"
                >
                  <i class="fas fa-trash clickable wd-100"></i>
                </div>
              </div>
              <div class="btn clickable mg-top-2" @click="saveVariations()">
                Salvar
                <i class="fas fa-angle-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import HeaderBuilder from "@/services/header-builder.js";
import { apiBaseUrl } from "@/services/api.js";
import Product from "@/services/product.js";
import util from "@/services/util";
import { Money } from "v-money";
import axios from "axios";
import Categories from "@/services/categories";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet

function generate_data() {
  return {
    step: 1,
    binaryDataImg: [],
    variationFiles: [],
    binaryDataFile: [],
    variationRelatedFiles: [],
    variationIndex: null,
    relatedFileCad: {
      name: "",
      description: "",
      published: true,
      downloadable: false,
      buyable: false,
    },
    loadIndex: 0,
    intervals: [
      { value: "MONTHLY", name: "Mensalmente" },
      { value: "WEEKLY", name: "Semanalmente" },
      { value: "DAILY", name: "Diariamente" },
    ],
    categories: [], // check
    productModal: {},
    searchParameters: {
      page: 0,
      perPage: 30,
      price_le: 0,
      price_ge: 0,
      ob_price: false,
      convert_prices: true,
    },
    product: {
      name: "",
      price: "R$ 0,00",
      plotted_price: "R$ 0,00",
      stocked_quantity: "",
      external_id: "",
      short_description: "",
      description: "",
      sellable: true,
      published: true,
      price_rules_enabled: true,
      root_price_enabled: true,
      tangible: true,
      categories: [],
      variations: [],
      box: {
        weight: 0,
        height: 0,
        width: 0,
        length: 0,
      },
      recurrent: false,
      recurrence_info: {
        payment_interval: "MONTHLY",
        payment_number: 1,
      },
    },
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false /* doesn't work with directive */,
    },
  };
}

export default {
  name: "FormProduct",
  data: generate_data,
  mounted() {
    Categories.list().then((categories) => {
      this.categories = categories;
    });
    this.listProducts();
    // Add event listener for search parameter change (navbar search usually)
    this.$root.$on("searchParameters", this.onSearchParametersEvent);
    this.$root.$emit("openSearch");
    setTimeout(() => window.addEventListener("scroll", this.handleScroll), 500);
    if (this.product_id) {
      this.showModal(this.product_id);
    }
  },
  computed: {
    isLoading: function () {
      return this.loadIndex > 0;
    },
  },
  methods: {
    showModal: function (productId) {
      this.variationIndex = null;
      this.loadIndex++;
      // product.price = product.price / 100;
      // product.price_rules = (product.price_rules || []).map((prule) => {
      // if (prule.modifier_type === "PRICE") {
      //    prule.amount = prule.amount / 100;
      // }
      //   return prule;

      // product.variations = (product.variations || []).map((pvar) => {
      //   pvar.price = pvar.price / 100;
      //   pvar.extends_price = (pvar.parent_fields || []).includes("price");
      //   pvar.extends_images = (pvar.parent_fields || []).includes("images");
      //   pvar.extends_box = (pvar.parent_fields || []).includes("box");
      //   return pvar;
      // });
      //   product.tangible = !!product.box;
      //   if (!product.box) {
      //     product.box = {};
      //   }
      //   product.recurrent = !!product.recurrence_info;
      //   if (!product.recurrence_info) {
      //     product.recurrence_info = {};
      //   }
      //   if (!product.categories) {
      //     product.categories = [];
      //   } else {
      //     product.categories = product.categories.map((c) => c.id);
      //   }
      //   (product.variations || []).forEach((variation) => {
      //     if (!variation.categories) {
      //       variation.categories = [];
      //     } else {
      //       variation.categories = variation.categories.map((c) => c.id);
      //     }
      //   });
      //   this.productModal = product;
      //   this.$refs.fileInput.value = "";
      //   if (this.$refs.relatedFileInput_o_RUT) {
      //     this.$refs.relatedFileInput_o_RUT.value = "";
      //   }
      // })
      this.$modal.show("product-manage-modal");
    },
    manageImages: function (variationIndex = null) {
      // this.product.variations = (this.product.variations || []).map((pvar) => {
      //   pvar.extends_images = (pvar.parent_fields || []).includes("images");
      //   return pvar;
      // });
      this.variationIndex = variationIndex;
      this.$modal.show("image-manage-modal");
    },
    manageRules: function () {
      this.variationIndex = null;
      this.$modal.show("rules-manage-modal");
    },
    manageVariations: function () {
      this.variationIndex = null;
      this.$modal.show("variations-manage-modal");
    },
    manageFiles: function (variationIndex = null) {
      this.variationIndex = variationIndex;
      this.$modal.show("file-manage-modal");
    },
    hideModal: function () {
      this.variationIndex = null;
      this.$refs.fileInput.value = "";
      this.$modal.hide("product-manage-modal");
    },
    hideImageModal: function () {
      this.$modal.hide("image-manage-modal");
    },
    hideFileModal: function () {
      this.$modal.hide("file-manage-modal");
    },
    hideRulesModal: function () {
      this.variationIndex = null;
      this.$modal.hide("rules-manage-modal");
    },
    hideVariationsModal: function () {
      this.variationIndex = null;
      this.$modal.hide("variations-manage-modal");
    },
    addRule: function () {
      this.productModal.price_rules = this.productModal.price_rules || [];
      this.productModal.price_rules.push({
        min_quantity: null,
        max_quantity: null,
        modifier_type: "PERCENTAGE",
        amount: null,
        enabled: true,
        relative: true,
      });
      this.$forceUpdate();
    },
    eraseRule: function (index) {
      this.variationIndex = null;
      this.productModal.price_rules.splice(index, 1);
      this.$forceUpdate();
    },
    saveRules: function () {
      this.loadIndex++;
      axios
        .put(
          `${apiBaseUrl}/api/product/${this.productModal.id}/rules`,
          {
            price_rules: this.productModal.price_rules.map((prule) => {
              prule = JSON.parse(JSON.stringify(prule));
              if (prule.modifier_type === "PRICE") {
                prule.amount = Number(String(prule.amount)) * 100;
              } else {
                prule.amount = Number(String(prule.amount).replace(/\D/g, ""));
              }
              return prule;
            }),
          },
          {
            headers: HeaderBuilder.build(),
          }
        )
        .then(
          (response) => {
            this.$notify({
              type: "success",
              title: "Regras Salvas",
              text: "Regras Salvas com Sucesso!",
            });
            this.$forceUpdate();
          },
          (error) => {
            this.$notify({
              type: "error",
              duration : 24000,
              title: "Erro ao Salvar Regras",
              text: util.stringifyAxiosError(error),
            });
          }
        )
        .finally(() => {
          this.loadIndex--;
        });
    },
    addVariation: function () {
      this.productModal.variations = this.productModal.variations || [];
      this.productModal.variations.push({
        name: "",
        price: "",
        stocked_quantity: "",
        description: "",
        categories: [],
        extends_price: true,
        extends_images: true,
        published: true,
        extends_box: true,
        extends_files: true,
        box: !this.productModal.tangible
          ? null
          : {
              weight: (this.productModal.box || {}).weight,
              length: (this.productModal.box || {}).length,
              width: (this.productModal.box || {}).width,
              height: (this.productModal.box || {}).height,
            },
        images: null,
        related_files: [],
      });
      this.$forceUpdate();
    },
    eraseVariation: function (index) {
      this.productModal.variations.splice(index, 1);
      this.$forceUpdate();
    },
    updateProduct: function () {
      var prod = JSON.parse(JSON.stringify(this.product));
      prod.price = prod.price * 100;
      prod.plotted_price = prod.plotted_price * 100;

      if (!prod.tangible) {
        prod.box = null;
      }

      if (prod.categories.length > 0 && !prod.categories[0]) {
        prod.categories = [];
      } else {
        prod.categories = prod.categories.map((id) => {
          return { id };
        });
      }

      this.loadIndex++;
      Product.edit(prod)
        .then(
          (serviceReturn) => {
            this.$notify({
              type: "success",
              title: "Produto Atualizado",
              text: "Produto Atualizado com Sucesso!",
            });

            this.productList = this.productList.map((p) =>
              p.id == prod.id ? prod : p
            );

            this.addImages(prod.id, null);
          },
          (error) => {
            this.hideModal();
            this.$notify({
              type: "error",
              duration : 24000,
              title: "Erro ao Editar Produto",
              text: util.stringifyAxiosError(error),
            });
          }
        )
        .finally(() => {
          this.loadIndex--;
        });
    },
    saveVariations: function () {
      this.loadIndex++;
      axios
        .put(
          `${apiBaseUrl}/api/product/${this.productModal.id}/variations`,
          {
            variations: this.productModal.variations.map((variation) => {
              variation = JSON.parse(JSON.stringify(variation));
              if (variation.price) {
                variation.price = Number(String(variation.price)) * 100;
              }
              if (
                (variation.categories || []).length > 0 &&
                !variation.categories[0]
              ) {
                variation.categories = [];
              } else {
                variation.categories = (variation.categories || []).map(
                  (id) => {
                    return { id };
                  }
                );
              }
              variation.parent_fields = [];
              if (variation.extends_price) {
                variation.parent_fields.push("price");
              }
              if (variation.extends_images) {
                variation.parent_fields.push("images");
              }
              if (variation.extends_box) {
                variation.parent_fields.push("box");
              }
              if (variation.extends_files) {
                variation.parent_fields.push("related_files");
              }
              return variation;
            }),
          },
          {
            headers: HeaderBuilder.build(),
          }
        )
        .then(
          (response) => {
            this.$notify({
              type: "success",
              title: "Variações Salvas",
              text: "Variações Salvas com Sucesso!",
            });
            this.productModal.variations.forEach((variation, variation_idx) => {
              variation.id = response.data[variation_idx].id;
              this.addImages(this.productModal.id, variation_idx);
            });
            this.$forceUpdate();
          },
          (error) => {
            this.$notify({
              type: "error",
              duration : 24000,
              title: "Erro ao Salvar Variações",
              text: util.stringifyAxiosError(error),
            });
          }
        )
        .finally(() => {
          this.loadIndex--;
        });
    },
    leftImage: function (index) {
      if (index == 0) {
        return;
      }
      if (this.variationIndex === null) {
        this.productModal.images = arrayMove(
          this.productModal.images,
          index,
          index - 1
        );
        this.productModal.imageUrl = arrayMove(
          this.productModal.imageUrl,
          index,
          index - 1
        );
      } else {
        this.productModal.variations[this.variationIndex].images = arrayMove(
          this.productModal.variations[this.variationIndex].images,
          index,
          index - 1
        );
        this.productModal.variations[this.variationIndex].imageUrl = arrayMove(
          this.productModal.variations[this.variationIndex].imageUrl,
          index,
          index - 1
        );
      }
      this.$refs.imageManageModal.$forceUpdate();
      this.commitImageMove(null, null, this.variationIndex);
    },
    rightImage: function (index) {
      if (
        index ==
        (this.variationIndex !== null
          ? this.productModal.variations[this.variationIndex].images
          : this.productModal.images
        ).length -
          1
      ) {
        return;
      }
      if (this.variationIndex === null) {
        this.productModal.images = arrayMove(
          this.productModal.images,
          index,
          index + 1
        );
        this.productModal.imageUrl = arrayMove(
          this.productModal.imageUrl,
          index,
          index + 1
        );
      } else {
        this.productModal.variations[this.variationIndex].images = arrayMove(
          this.productModal.variations[this.variationIndex].images,
          index,
          index + 1
        );
        this.productModal.variations[this.variationIndex].imageUrl = arrayMove(
          this.productModal.variations[this.variationIndex].imageUrl,
          index,
          index + 1
        );
      }
      this.$refs.imageManageModal.$forceUpdate();
      this.commitImageMove(null, null, this.variationIndex);
    },
    leftFile: function (index) {
      if (index == 0) {
        return;
      }
      if (this.variationIndex === null) {
        this.productModal.related_files = arrayMove(
          this.productModal.related_files,
          index,
          index - 1
        );
      } else {
        this.productModal.variations[
          this.variationIndex
        ].related_files = arrayMove(
          this.productModal.variations[this.variationIndex].related_files,
          index,
          index - 1
        );
      }
      this.$refs.fileManageModal.$forceUpdate();
      this.commitFileMove(null, null, this.variationIndex);
    },
    rightFile: function (index) {
      if (
        index ==
        (this.variationIndex !== null
          ? this.productModal.variations[this.variationIndex].related_files
          : this.productModal.related_files
        ).length -
          1
      ) {
        return;
      }
      if (this.variationIndex === null) {
        this.productModal.related_files = arrayMove(
          this.productModal.related_files,
          index,
          index + 1
        );
      } else {
        this.productModal.variations[
          this.variationIndex
        ].related_files = arrayMove(
          this.productModal.variations[this.variationIndex].related_files,
          index,
          index + 1
        );
      }
      this.$refs.fileManageModal.$forceUpdate();
      this.commitFileMove(null, null, this.variationIndex);
    },
    commitImageMove: function (
      id = null,
      images = null,
      variation_index = null
    ) {
      axios
        .patch(
          `${apiBaseUrl}/api/product/${id ? id : this.productModal.id}/images`,
          util.cleanObject({
            images: images
              ? images
              : variation_index !== null
              ? this.productModal.variations[variation_index].images
              : this.productModal.images,
            variation_id:
              variation_index !== null
                ? this.productModal.variations[variation_index].id
                : null,
          }),
          {
            headers: HeaderBuilder.build(),
          }
        )
        .then(
          (response) => {},
          (error) => {
            this.$notify({
              type: "error",
              duration : 24000,
              title: "Erro ao Mover Imagem",
              text: util.stringifyAxiosError(error),
            });
          }
        );
    },
    commitFileMove: function (
      id = null,
      related_files = null,
      variation_index = null
    ) {
      axios
        .patch(
          `${apiBaseUrl}/api/product/${
            id ? id : this.productModal.id
          }/related_files`,
          util.cleanObject({
            related_files: related_files
              ? related_files
              : variation_index !== null
              ? this.productModal.variations[variation_index].related_files
              : this.productModal.related_files,
            variation_id:
              variation_index !== null
                ? this.productModal.variations[variation_index].id
                : null,
          }),
          {
            headers: HeaderBuilder.build(),
          }
        )
        .then(
          (response) => {},
          (error) => {
            this.$notify({
              type: "error",
              duration : 24000,
              title: "Erro ao Mover Arquivo",
              text: util.stringifyAxiosError(error),
            });
          }
        );
    },
    eraseImage: function (imgId) {
      let pid = this.productModal.id;
      let vari =
        this.variationIndex === null
          ? null
          : this.productModal.variations[this.variationIndex];
      axios
        .delete(
          `${apiBaseUrl}/api/product/${pid}/image/${imgId}` +
            `?apartment=${process.env.VUE_APP_TENANT_ID}` +
            (vari ? `&variation_id=${vari.id}` : ""),
          {
            headers: HeaderBuilder.build(),
          }
        )
        .then(
          (response) => {
            this.$notify({
              type: "success",
              title: "Imagem Excluída",
              text: "Imagem Excluída com Sucesso!",
            });
            if (vari) {
              this.productModal.variations[
                this.variationIndex
              ].images = this.productModal.variations[
                this.variationIndex
              ].images.filter((i) => i != imgId);
              this.productModal.variations[
                this.variationIndex
              ].imageUrl = this.productModal.variations[
                this.variationIndex
              ].imageUrl.filter((iu) => !String(iu).includes(imgId));
            } else {
              this.productModal.images = this.productModal.images.filter(
                (i) => i != imgId
              );
              this.productModal.imageUrl = this.productModal.imageUrl.filter(
                (iu) => !String(iu).includes(imgId)
              );
            }
            this.productList = this.productList.map((p) =>
              p.id == this.productModal.id ? this.productModal : p
            );
            this.$forceUpdate();
          },
          (error) => {
            this.$notify({
              type: "error",
              duration : 24000,
              title: "Erro ao Excluir Imagem",
              text: util.stringifyAxiosError(error),
            });
          }
        );
    },
    eraseFile: function (fileObj) {
      let pid = this.productModal.id;
      let vari =
        this.variationIndex === null
          ? null
          : this.productModal.variations[this.variationIndex];
      const fileObjId = fileObj.id;
      axios
        .delete(
          `${apiBaseUrl}/api/product/${pid}/related_file/${fileObjId}` +
            `?apartment=${process.env.VUE_APP_TENANT_ID}` +
            (vari ? `&variation_id=${vari.id}` : ""),
          {
            headers: HeaderBuilder.build(),
          }
        )
        .then(
          (response) => {
            this.$notify({
              type: "success",
              title: "Arquivo Excluído",
              text: "Arquivo Excluído com Sucesso!",
            });
            if (vari) {
              this.productModal.variations[
                this.variationIndex
              ].related_files = this.productModal.variations[
                this.variationIndex
              ].related_files.filter((i) => i != fileObjId);
            } else {
              this.productModal.related_files = this.productModal.related_files.filter(
                (i) => i != fileObjId
              );
            }
            this.productList = this.productList.map((p) =>
              p.id == this.productModal.id ? this.productModal : p
            );
            this.$forceUpdate();
          },
          (error) => {
            this.$notify({
              type: "error",
              duration : 24000,
              title: "Erro ao Excluir Arquivo",
              text: util.stringifyAxiosError(error),
            });
          }
        );
    },
    addImages: function (product_id = null, variation_index = null) {
      let qtyImages =
        variation_index !== null
          ? (this.variationFiles[variation_index] || []).length
            ? (this.variationFiles[variation_index] || []).length
            : 0
          : this.binaryDataImg.length
          ? this.binaryDataImg.length
          : 0;

      if (qtyImages <= 0) {
        return;
      }

      this.$notify({
        type: "info",
        title: "Adicionando Imagens",
        text: "As imagens estão sendo adicionadas...",
      });

      let finishedImages = [];
      let preExistingImages = (
        (variation_index !== null
          ? this.productModal.variations[variation_index].images
          : this.productModal.images) || []
      ).slice();

      for (let i = 0; i < qtyImages; i++) {
        ((index) => {
          this.loadIndex++;
          axios
            .put(
              `${apiBaseUrl}/api/product/${product_id}/image` +
                (variation_index !== null
                  ? `?variation_id=${this.productModal.variations[variation_index].id}`
                  : ""),
              variation_index !== null
                ? this.variationFiles[variation_index][index]
                : this.binaryDataImg[index],
              {
                headers: HeaderBuilder.build(),
              }
            )
            .then(
              (response) => {
                const actedUpon = variation_index
                  ? this.productModal.variations[variation_index]
                  : this.productModal;
                if (actedUpon && actedUpon.images && response.data) {
                  if (!actedUpon.images) {
                    actedUpon.images = [];
                  }
                  actedUpon.images.push(response.data.id);
                  if (!actedUpon.imageUrl) {
                    actedUpon.imageUrl = [];
                  }
                  actedUpon.imageUrl.push(
                    Product.buildImageUrl(
                      this.productModal.id,
                      response.data.id,
                      variation_index ? actedUpon.id : null
                    )
                  );
                  this.$forceUpdate();
                }
                finishedImages[index] = response.data.id;
                if (
                  // Ensure at least length matches
                  finishedImages.length === qtyImages &&
                  // Ensure no null or undefined elements
                  !finishedImages.some((v) => typeof v !== "string") &&
                  // Ensure not sparse
                  Object.values(finishedImages).length === finishedImages.length
                ) {
                  if (variation_index === null) {
                    this.hideModal();
                  } else {
                    this.hideVariationsModal();
                  }
                  this.$notify({
                    type: "success",
                    title: "Imagens adicionadas com sucesso!",
                    text:
                      "As imagens foram adicionadas e ordenadas com sucesso!",
                  });
                  this.commitImageMove(
                    product_id,
                    preExistingImages.concat(finishedImages),
                    variation_index
                  );
                }
              },
              (error) => {
                this.$notify({
                  type: "error",
                  title: "Erro ao adicionar imagem no produto",
                  text: util.stringifyAxiosError(error),
                });
              }
            )
            .finally(() => {
              this.loadIndex--;
            });
        })(i);
        if (this.$refs["fileInput_var_" + variation_index]) {
          this.$refs["fileInput_var_" + variation_index].value = "";
        }
      }
    },
    addFiles: function (product_id = null, variation_index = null) {
      let qtyFiles =
        variation_index !== null
          ? (this.variationRelatedFiles[variation_index] || []).length
            ? (this.variationRelatedFiles[variation_index] || []).length
            : 0
          : this.binaryDataFile.length
          ? this.binaryDataFile.length
          : 0;

      if (qtyFiles <= 0) {
        return;
      }

      this.$notify({
        type: "info",
        title: "Adicionando Arquivos",
        text: "Os arquivos estão sendo adicionados...",
      });

      let finishedFiles = [];

      for (let i = 0; i < qtyFiles; i++) {
        ((index) => {
          this.loadIndex++;
          axios
            .put(
              `${apiBaseUrl}/api/product/${product_id}/related_file` +
                (variation_index !== null
                  ? `?variation_id=${this.productModal.variations[variation_index].id}`
                  : ""),
              variation_index !== null
                ? this.variationRelatedFiles[variation_index][index]
                : this.binaryDataFile[index],
              {
                headers: {
                  ...HeaderBuilder.build(),
                  ...{
                    "X-Zankh-Downloadable": String(
                      this.relatedFileCad.downloadable
                    ),
                    "X-Zankh-Published": String(this.relatedFileCad.published),
                    "X-Zankh-Buyable": String(this.relatedFileCad.buyable),
                    "X-Zankh-Name": this.relatedFileCad.name,
                    "X-Zankh-Description": this.relatedFileCad.description,
                  },
                },
              }
            )
            .then(
              (response) => {
                const actedUpon = variation_index
                  ? this.productModal.variations[variation_index]
                  : this.product;
                if (actedUpon && actedUpon.related_files && response.data) {
                  actedUpon.related_files.push(response.data);
                  this.$forceUpdate();
                }
                finishedFiles[index] = response.data.id;
                if (
                  // Ensure at least length matches
                  finishedFiles.length === qtyFiles &&
                  // Ensure no null or undefined elements
                  !finishedFiles.some((v) => !v || typeof v.id !== "string") &&
                  // Ensure not sparse
                  Object.values(finishedFiles).length === finishedFiles.length
                ) {
                  if (variation_index === null) {
                    this.hideModal();
                  } else {
                    this.hideVariationsModal();
                  }
                  this.$notify({
                    type: "success",
                    title: "Arquivos adicionados com sucesso!",
                    text:
                      "Os arquivos foram adicionados e ordenados com sucesso!",
                  });
                  this.commitFileMove(
                    product_id,
                    (variation_index !== null
                      ? this.productModal.variations[variation_index]
                          .related_file
                      : this.productModal.related_file
                    ).concat(finishedFiles),
                    variation_index
                  );
                }
              },
              (error) => {
                this.$notify({
                  type: "error",
                  title: "Erro ao adicionar arquivo ao produto!",
                  text: util.stringifyAxiosError(error),
                });
              }
            )
            .finally(() => {
              this.loadIndex--;
            });
        })(i);
        if (this.$refs["relatedFileInput_o_" + variation_index]) {
          this.$refs["relatedFileInput_o_" + variation_index].value = "";
        }
      }
    },
    onSearchParametersEvent: function (searchParameters) {
      this.$root.$emit("searchListened");
      Object.assign(this.searchParameters, searchParameters);
      this.searchParameters.page = 0;
      this.loadingMore = false;
      this.over = false;
      this.listProducts();
    },
    listProducts(push = false) {
      if (push) {
        if (this.loadingMore || this.over) {
          return;
        }
        this.searchParameters.page++;
        this.loadingMore = true;
      } else {
        this.searchParameters.page = 0;
        this.loadingMore = false;
        this.over = false;
        this.loadIndex++;
      }
      Product.list(this.searchParameters)
        .then((products) => {
          products.forEach((product) => {
            if (!product.categories) {
              product.categories = [];
            } else {
              product.categories = product.categories.map((c) => c.id);
            }
          });
          if (!push) {
            this.productList = products;
          } else {
            if (products.length === 0) {
              this.over = true;
            }
            this.productList.push(...products);
            this.$forceUpdate();
            setTimeout(() => (this.loadingMore = false), 100);
          }
        })
        .finally(() => {
          if (!push) {
            this.loadIndex--;
          }
        });
    },
    registerProduct: function () {
      this.loadIndex++;

      if (this.product.categories.length > 0 && !this.product.categories[0]) {
        this.product.categories = [];
      } else {
        this.product.categories = this.product.categories.map((id) => {
          return { id };
        });
      }

      var prod = JSON.parse(JSON.stringify(this.product));
      prod.price = prod.price * 100;
      prod.plotted_price = prod.plotted_price * 100;
      if (!prod.tangible) {
        prod.box = null;
      }
      if (!prod.recurrent) {
        prod.recurrence_info = null;
      }
      Product.create(prod)
        .then(
          (serviceReturn) => {
            var qtyImages = this.binaryDataImg.length
              ? this.binaryDataImg.length
              : 0;

            if (qtyImages > 0) {
              this.$notify({
                type: "info",
                title: "Adicionando Imagens",
                text: "As imagens estão sendo adicionadas...",
              });

              let finishedImages = [];

              for (let i = 0; i < qtyImages; i++) {
                ((index) => {
                  this.loadIndex++;
                  axios
                    .put(
                      `${apiBaseUrl}/api/product/${serviceReturn.id}/image`,
                      this.binaryDataImg[index],
                      {
                        headers: HeaderBuilder.build(),
                      }
                    )
                    .then(
                      (response) => {
                        finishedImages[index] = response.data.id;
                        if (
                          finishedImages.filter((v) => v).length == qtyImages
                        ) {
                          this.loadIndex++;
                          axios
                            .patch(
                              `${apiBaseUrl}/api/product/${serviceReturn.id}/images`,
                              {
                                images: finishedImages,
                              },
                              {
                                headers: HeaderBuilder.build(),
                              }
                            )
                            .then(
                              (response) => {
                                this.$notify({
                                  type: "success",
                                  title: "Imagens adicionadas com sucesso!",
                                  text:
                                    "As imagens foram adicionadas e ordenadas com sucesso!",
                                });
                              },
                              (error) => {
                                this.$notify({
                                  type: "error",
                                  title: "Erro ao mover Imagens",
                                  text: util.stringifyAxiosError(error),
                                });
                              }
                            )
                            .finally(() => {
                              this.loadIndex--;
                            });
                        }
                      },
                      (errorResponse) => {
                        this.$notify({
                          type: "error",
                          title: "Erro ao adicionar imagem ao produto!",
                          text: util.stringifyAxiosError(errorResponse),
                        });
                      }
                    )
                    .finally(() => {
                      this.loadIndex--;
                    });
                })(i);
              }
            }

            this.$notify({
              type: "success",
              title: "Produto Cadastrado",
              text: "Produto Cadastrado com Sucesso!",
            });

            Categories.list().then((categories) => {
              this.categories = categories;
            });
            Object.assign(this.$data, {
              ...generate_data(),
              loadIndex: this.loadIndex,
            });
            this.step = 2;
          },
          (errorResponse) => {
            this.$notify({
              type: "error",
              duration : 24000,
              title: "Erro ao Cadastrar Produto",
              text: util.stringifyAxiosError(errorResponse),
            });
          }
        )
        .finally(() => {
          this.loadIndex--;
        });
    },
    processFile(event) {
      this.binaryDataImg = event.target.files;
    },
  },
  components: {
    money: Money,
    Loading,
  },
};
</script>